import Geosuggest from "@ubilabs/react-geosuggest";
import { Button, Divider, Input, InputMask, Select, StepsWrapper } from "components";
import { Fragment, useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import styles from "pages/settings/GoodLooks.module.css"
import { maskString, ucFirst } from "utils/formatter";
import { useAppSelector, useValidation } from "hooks";
import { AutoComplete, Collapse, DatePicker, notification, TimePicker } from "antd";
import { getProductsByZone, locateZone } from "api";
import Cart, { AddressI, CustomerI } from "./Cart";
import { currencyEnum } from "utils/enums";
import { setEvents } from "store/hunters/hunters_t";
import { useDispatch } from "react-redux";
import { RangePickerProps } from "antd/lib/date-picker";
import moment, { Moment } from "moment";
import dayjs from "dayjs";
import es from 'dayjs/locale/es';
const TIME_FORMAT = 'HH:mm';
 const AddServiceForm = ()=> { 

    const { Panel } = Collapse;

    const dispatch = useDispatch();
    const { promoCodes } = useAppSelector(({ hunters }) => hunters);
    
    const inputRef = useRef<Geosuggest>(null);
    const [isDisabled, setIsDisabled] = useState(true);
    const [name, setName] = useState("");
    const [lastNames, setLastNames] = useState("");
    const [phone, setPhone] = useState("");
    const [mail, setMail] = useState("");
    const [emailInvFormat, setEmailInvFormat] = useState(false);
    const { wordValidation, emailValidation } = useValidation();
    const [zoneSelected, setZoneSelected] = useState<any>(undefined);
    const [address, setAddress] = useState<any>(undefined);
    const [street, setStreet] = useState("");
    const [vicinity, setVicinity] = useState("");
    const [zoneName, setZoneName] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [streetNumber, setStreetNumber] = useState("");
    const [state,setState ] = useState("");
    const [city, setCity] = useState("");
    const [reference, setReference] = useState("");
    const [alias, setAlias] = useState("");
    const [country, setCountry] = useState("");
    const [activeTab, setActiveTab] = useState(1);
    const [located, setLocated] = useState<any>(undefined);
    const [productsList, setProductsList] = useState([]);
    const [options, setOptions] = useState<any>([]);
    const [cart, setCart] = useState<any>([]);
    const [currency, setCurrency] = useState <'MXN' | 'USD' | 'EUR'> ("MXN");
    const [customer, setCustomer] = useState<CustomerI>();
    const [reqAddress, setReqAddress] = useState<AddressI>();
    const [valueAutoComplete, setValueAutoComplete] = useState("");
    const [promoCode, setPromoCode] = useState<any>();
    const [codeOptions, setCodeOptions] = useState<any>([]);
    const [selectedDate, setSelectedDate] = useState("");
    const [timeSelected, setTimeSelected] = useState("09:00");

    const formInputEnabled = !(zoneSelected !== undefined);
    
    const clearFields = () => {
        setIsDisabled(true);
        setName("");
        setLastNames("");
        setPhone("");
        setMail("");
        setEmailInvFormat(false);
        setZoneSelected(undefined);
        setAddress(undefined);
        setStreet("");
        setVicinity("");
        setZoneName("");
        setPostalCode("");
        setStreetNumber("");
        setState("");
        setCity("");
        setReference("");
        setAlias("");
        setCountry("");
        setActiveTab(1);
        setLocated(undefined);
        setProductsList([]);
        setOptions([]);
        setCart([]);
        setCurrency("MXN");
        setCustomer(undefined);
        setReqAddress(undefined);
        setValueAutoComplete("");
        setPromoCode(undefined);
        setCodeOptions([]);
        setSelectedDate("");
        setTimeSelected("09:00");
    }
    
    const handleChange = ({target}:any) => {
        const {value, id} = target;
        switch (id) {
            case "name":
                setName(prev => wordValidation(value) ? ucFirst(value) : prev);
                break;
            case "street":
                setStreet(prev => wordValidation(value) ? ucFirst(value) : prev);
                break;
            case "vicinity":
                setVicinity(prev => wordValidation(value) ? ucFirst(value) : prev);
                break;
            case "state":
                setState(prev => wordValidation(value) ? ucFirst(value) : prev);
                break;
            case "city":
                setCity(prev => wordValidation(value) ? ucFirst(value) : prev);
                break;
            case "country":
                setCountry(prev => wordValidation(value) ? ucFirst(value) : prev);
                break;
            case "lastnames":
                setLastNames(prev => wordValidation(value) ? ucFirst(value) : prev)
                break;
            case "postalCode":
                setPostalCode(maskString(value.trim(), "#####"))
                break;
            case "phone":
                setPhone(maskString(value.trim(), "## #### ####"))
                break;
            case "streetNumber":
                setStreetNumber(maskString(value.trim(), "#####"));
                break;
            case "reference":
                    setReference(prev => wordValidation(value) ? ucFirst(value) : prev);
                    break;
            case "alias":
                    setAlias(prev => wordValidation(value) ? ucFirst(value) : prev);
                    break;
            case "mail":
                setMail(value.toLowerCase().trim())
                break;
            default:
                return null;
        }
    }

    

    const handleChangeMaskedInput = (value:any) => {
        setPhone(value);
    }

    const handleChangeMaskedInputPC = (value:any) => {
        setPostalCode(value);
    }

    const handleChangeMaskedInputSN = (value:any) => {
        setStreetNumber(value);
    }

    const handleValidation = () => {
        if (!mail || emailValidation(mail)) {
            return setEmailInvFormat(false);
        }
        setEmailInvFormat(true);
    }

    const getProperty = (prop:string, shortName=false) => {
        return address.find((item:any) => item.types.includes(prop))?.[shortName ? "short_name" : "long_name"] || "";
    }

    const getItem = (name:string) => {
        return options.find((item:any) => name === item.value);
    }

    const getCodeItem = (name:string) => {
        return codeOptions.find((item:any) => name === item.value);
    }

    const getFactor = async () => {
        const body = {
            locality: `${getProperty("locality")}`,
            aal1: `${getProperty("administrative_area_level_1")}`,
            aal2: '',
            sublocality: `${getProperty("sublocality_level_1")}`,
            neighborhood: ''
        }
        try{
            const {data} = await locateZone(body);
            if(data){
                setLocated(data);
            }
        }catch(e:any){
            console.log('e: ', e);
        }
    }

    const getProducts = async () => {
        const body = {
            raw: true,
            zone: located ? located.id : 0,
        }
        try{
            const {data} = await getProductsByZone(body);
            if(data){
                setProductsList(data);
            }
        }catch(e:any){
            console.log('e: ', e);
        }
    }


    const handleSearch = (value:any) => {
        setZoneName(value);
        setZoneSelected(undefined);
        setAddress(undefined);
        if(value === ''){
            clearFields();
            setIsDisabled(true);
        }
    }
    const handleSelect = (typed: string) => (value:any) => {
        switch (typed) {
            case "currency":
                setCurrency(value);
                break;
            default:
                return null;
        }
    }

    const filterProducts = (search:string) => {
        if (productsList.length > 0) {
            const aux = productsList.filter((item:any) => {
                const { name, description } = item;
                const tempName = name ? name.es.toLowerCase() : "";
                const tempDescription = description ? description.es.toLowerCase() : "";
                return tempName.match(search.toLowerCase()) || tempDescription.match(search.toLowerCase());
            });

            return aux.map((a:any)=> { return {value: a.name.es, obj: a} } );
        }
    }

    const filterCodes = (search:string) => {
        if (promoCodes.data.length > 0) {
            const aux = promoCodes.data.filter((item:any) => {
                const { code } = item;
                const tempName = code ? code.toUpperCase() : "";
                return tempName.match(search.toUpperCase());
            });

            return aux.map((a:any)=> { return {value: a.code, obj: a} } );
        }
    }

    const handleContinue = ()=> {
        setActiveTab(2);
        setCustomer({
            name,
            lastNames,
            phone,
            email: mail
        });
        setReqAddress({
            latitude:zoneSelected.location.lat.toString(),
            longitude:zoneSelected.location.lng.toString(),
            street,
            number: streetNumber, 
            zipcode: postalCode,
            details: reference,
            zone_id: located ? located.id : 0,
            alias,
            locality: city,
            sublocality:vicinity,
            neighborhood:`${getProperty("neighborhood") !== "" ? getProperty("neighborhood") : vicinity}`,
            administrative_area_level_1: state,
        });
    }

    const handleAutoComplete = (text:string) => {
        setValueAutoComplete(text);
        if(text === ''){
            setOptions([]);
        }else{
            setOptions(filterProducts(text.trimStart())?.sort((a:any,b:any) => a.value>b.value ? 1 : -1) );
        }
    }

    const disabledDate: RangePickerProps['disabledDate'] = current => {
        const sixMonthsFromNow = moment().add(6, 'months').endOf('day');
        const endOfYesterday = moment().subtract(1, 'days').startOf('day');
        return current && (current < endOfYesterday || current > sixMonthsFromNow);
      };

    const disabledTime = (current: Moment | null) => {
        if (!current) {
            return {};
        }
    
        const hours: number[] = [];
        const minutes: number[] = [];
        const seconds: number[] = [];
        
        for (let i = 0; i < 24; i++) {
            if (i < 6 || i > 21) {
            hours.push(i);
            }
        }
        
        return {
            disabledHours: () => hours,
            disabledMinutes: () => minutes,
            disabledSeconds: () => seconds
        };
    };
 
    useEffect(()=>{
        if(located && located.id >= 0){
            getProducts();
        }
    },[located]);

    useEffect(() => {
        if (zoneSelected) {
            const { gmaps } = zoneSelected;
            setZoneName("");
            setAddress(gmaps.address_components);
        }
    }, [zoneSelected]);

    useEffect(()=>{
        if(address){
            setStreet(`${getProperty("route")}`);
            setStreetNumber(`${getProperty("street_number")}`);
            setVicinity(`${getProperty("sublocality_level_1")}`);
            setPostalCode(`${getProperty("postal_code")}`);
            setCity(`${getProperty("locality")}`);
            setState(`${getProperty("administrative_area_level_1")}`);
            setCountry(`${getProperty("country")}`);
            getFactor();
        }
    },[address]);
    

    useEffect(() => {
        if(name !== "" && lastNames !== "" && phone !== "" && mail !== "" && street !== "" &&
            streetNumber !== "" && vicinity !== "" && postalCode !== "" && city !== "" &&
            state !== "" && country !== "" && reference !== "" && alias !== ""
         ){
            handleValidation();
            setIsDisabled(false);
        }else{
            setIsDisabled(true);
        }
    },[name, lastNames, phone, mail, street, streetNumber, vicinity, postalCode, city, state, country, reference, alias])

    useEffect(()=>{
        dispatch(setEvents());
        dayjs.locale(es);
    },[]);


     return ( 
        <div>
            <StepsWrapper
                percentBar={50 * activeTab}
                active={activeTab}
                steps={[
                    {
                        id: 1,
                        text: `Información Cliente`,
                        icon: 'detailPromotionIcon'
                    },
                    {
                        id: 2,
                        text: `Carrito`,
                        icon: 'detailPromotionIcon'
                    },
                
                ]}
            />
                <Form>
                    <Collapse className='collapse-register' activeKey={activeTab.toString()} style={{border: "none", backgroundColor: "white"}} >
                        {
                            activeTab === 1 &&
                                <Panel className='register-panel' showArrow={false} header="" key={"1"} style={{border: "none"}} >
                                    <Row>
                                        <h6 style={{margin: '1rem 0 2rem 0'}} >{"Datos de Registro"}</h6>
                                        <Col xs={12} sm={12} md={4} lg={4}>
                                            <Input
                                                id="name"
                                                name="name"
                                                label="Nombre"
                                                classNameContainer="mb-2"
                                                value={name}
                                                onChange={handleChange}
                                                maxLength={50}
                                                showCount
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={4} lg={4}>
                                            <Input
                                                id="lastnames"
                                                name="lastnames"
                                                label="Apellidos"
                                                classNameContainer="mb-2"
                                                value={lastNames}
                                                onChange={handleChange}
                                                maxLength={50}
                                                showCount
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={4} lg={4}>
                                            <InputMask
                                                id="phone"
                                                classNameContainer="mb-2"
                                                name="phone"
                                                label="Teléfono"
                                                value={phone}
                                                mask="99 9999 9999"
                                                onChange={handleChangeMaskedInput}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={4} lg={4}>
                                            <Input
                                                classNameContainer="mb-2" 
                                                label="Correo"
                                                name="mail"
                                                id="mail"
                                                value={mail}
                                                onChange={handleChange}
                                                text={emailInvFormat ? "Formato de Correo Invalido" : ""}
                                                classNameText={emailInvFormat ? "wrong-text" : ""}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={4} lg={4} style={{display: 'flex', flexDirection: 'column'}}>
                                            <label className="form-label">Fecha</label>
                                            <DatePicker
                                                format="DD-MMM-YYYY"
                                                disabledDate={disabledDate}
                                                onSelect={(date:any)=>{
                                                    setSelectedDate(dayjs(new Date(date)).format("YYYY-MM-DD").toString())
                                                }}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={4} lg={4} style={{display: 'flex', flexDirection: 'column'}}>
                                            <label className="form-label">Hora</label>
                                            <TimePicker
                                                className="schedulePicker"
                                                placeholder={'Ingresa hora'}
                                                format={TIME_FORMAT}
                                                onChange={(text:any)=> {
                                                    const aux = new Date(text);
                                                    const time = `${aux.getHours() <= 9 ? `0${aux.getHours()}` : aux.getHours()}:${aux.getMinutes() === 0 ? '00' : aux.getMinutes()}`;
                                                    setTimeSelected(time);
                                                }}
                                                minuteStep={30}
                                                disabledTime={disabledTime}
                                                showNow={false}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <h6 style={{margin: '1rem 0 2rem 0'}} >{"Dirección"}</h6>
                                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                            <p className="form-label" >Ingresa una dirección y selecciona la que buscas:</p>
                                            <Geosuggest
                                                ref={inputRef}
                                                inputType="search"
                                                inputClassName="ant-input"
                                                suggestItemClassName={styles["list-google"]}
                                                types={["geocode"]}
                                                placeholder="Ingresa calle, número, col, etc."
                                                onSuggestSelect={(suggest:any) => {
                                                    setZoneSelected(suggest);
                                                }}
                                                renderSuggestItem={(suggest) => {
                                                    const { description } = suggest;
                                                    return (
                                                        <Fragment>
                                                                {zoneName &&
                                                                <div>
                                                                    <p className="m-0">Dirección:</p>
                                                                    <p className="m-0">{description}</p>
                                                                    <Divider style={{ margin: "8px 0"}} />
                                                                </div>}
                                                        </Fragment>
                                                    )
                                                }}
                                                // value={zoneSelected}
                                                onChange={handleSearch}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={12} md={4} lg={4}>
                                            <Input
                                                id="street"
                                                name="street"
                                                label="Calle"
                                                classNameContainer="mb-2"
                                                value={street}
                                                onChange={handleChange}
                                                maxLength={50}
                                                showCount
                                                disabled={formInputEnabled}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={4} lg={4}>
                                            <InputMask
                                                id="streetNumber"
                                                classNameContainer="mb-2"
                                                name="streetNumber"
                                                label="Número"
                                                value={streetNumber}
                                                mask="99999"
                                                onChange={handleChangeMaskedInputSN}
                                                isDisabled={formInputEnabled}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={4} lg={4}>
                                            <Input
                                                id="vicinity"
                                                name="vicinity"
                                                label="Colonia"
                                                classNameContainer="mb-2"
                                                value={vicinity}
                                                onChange={handleChange}
                                                maxLength={50}
                                                showCount
                                                disabled={formInputEnabled}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={4} lg={4}>
                                            <InputMask
                                                id="postalCode"
                                                classNameContainer="mb-2"
                                                name="postalCode"
                                                label="Codigo Postal"
                                                value={postalCode}
                                                mask="99999"
                                                onChange={handleChangeMaskedInputPC}
                                                isDisabled={formInputEnabled}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={4} lg={4}>
                                            <Input
                                                classNameContainer="mb-2" 
                                                label="Ciudad"
                                                name="city"
                                                id="city"
                                                value={city}
                                                onChange={handleChange}
                                                disabled={formInputEnabled}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={4} lg={4}>
                                            <Input
                                                classNameContainer="mb-2" 
                                                label="Estado"
                                                name="state"
                                                id="state"
                                                value={state}
                                                onChange={handleChange}
                                                disabled={formInputEnabled}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={4} lg={4}>
                                            <Input
                                                classNameContainer="mb-2" 
                                                label="Pais"
                                                name="country"
                                                id="country"
                                                value={country}
                                                onChange={handleChange}
                                                disabled={formInputEnabled}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={4} lg={4}>
                                            <Input
                                                id="reference"
                                                name="reference"
                                                label="Referencias"
                                                classNameContainer="mb-2"
                                                value={reference}
                                                onChange={handleChange}
                                                maxLength={50}
                                                showCount
                                                disabled={formInputEnabled}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={4} lg={4}>
                                            <Input
                                                id="alias"
                                                name="alias"
                                                label="Alias"
                                                classNameContainer="mb-2"
                                                value={alias}
                                                onChange={handleChange}
                                                maxLength={50}
                                                showCount
                                                disabled={formInputEnabled}
                                            />
                                        </Col>
                                    </Row>
                                    <Divider style={{ margin: "16px 0"}} />
                                    <Row style={{display: 'flex', justifyContent: 'center'}} >
                                        <Col style={{width: '20%'}} xs={12} sm={12} md={12} lg={12}>
                                            <Button size="sm" onClick={handleContinue} className="primary-btn" style={{width: '100%'}} disabled={isDisabled}>
                                                {"Continuar"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Panel>
                        }
                            <Panel className='register-panel' showArrow={false} header="" key={"2"} style={{border: "none"}} >
                                <Row style={{marginBottom: '1rem'}}  >
                                    <Col xs={6} sm={6} md={4} lg={4}>
                                        <h6 style={{marginBottom: '5px', fontWeight: 300}} >{"Servicios Adquiridos"}</h6>
                                        <AutoComplete
                                            options={options}
                                            style={{ width: 300 }}
                                            onSelect={(obj:any)=>{
                                                //Adding elements to the cart
                                                if(Object.values(cart.filter((c:any)=> c.name.es === getItem(obj).obj.name.es )).length > 0){
                                                    notification.info({
                                                        message: 'Servicio Agregado',
                                                        description: 'El servicio ya se encuentra en el carrito.'
                                                    });
                                                }else{
                                                    setCart([...cart, {...getItem(obj).obj, count: 1} ]);
                                                    handleAutoComplete("");
                                                }
                                                
                                            }}
                                            value={valueAutoComplete}
                                            onSearch={(text:string) => handleAutoComplete(text) }
                                            placeholder="Ingresa el Nombre del Servicio"
                                        />
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                                        <h6 style={{marginBottom: '5px', fontWeight: 300}} >{"Código de Descuento"}</h6>
                                        <AutoComplete
                                            options={codeOptions}
                                            style={{ width: 300 }}
                                            onSelect={(obj:any)=>{
                                                setPromoCode(getCodeItem(obj));
                                            }}
                                            onSearch={(text:string) => {
                                    
                                                if(text === ''){
                                                    setCodeOptions([]);
                                                    setPromoCode({value: "", obj: {discount: 0}});
                                                }else{
                                                    setCodeOptions(filterCodes(text.trimStart()).sort((a:any,b:any) => a.value>b.value ? 1 : -1));
                                                }
                                            } }
                                            placeholder="Ingresa el Código"
                                        />
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                                        <h6 style={{marginBottom: '5px', fontWeight: 300}} >{"Moneda"}</h6>
                                        <Select
                                            id="statusEvent"
                                            classNameContainer="mb-2"
                                            options={currencyEnum.map((item) => ({ value: item.value, label: item.label }))}
                                            value={currency}
                                            onChange={handleSelect("currency")}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Cart 
                                        list={cart}
                                        goBack={setActiveTab}
                                        clearForm={clearFields}
                                        currency={currency}
                                        updateCart={setCart} 
                                        promoCode={promoCode?.obj}
                                        requestBody={customer && reqAddress ? {
                                            sale: {
                                                date: `${selectedDate} ${timeSelected}:00`,
                                                payment_type: "cash",
                                            },
                                            customer,
                                            address: reqAddress
                                        } : {}}
                                    />
                                </Row>
                            </Panel>
                    </Collapse>
                </Form>
        </div>
        )} 

 export default AddServiceForm;