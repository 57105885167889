
import { UserOutlined } from "@ant-design/icons";
import { Layout, MenuProps } from "antd";
import { AppSider, Avatar, Dropdown, Footer, Image } from "components";
import { useAppSelector } from "hooks";
import { FC, Fragment, useContext } from "react";
import { Navbar } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import styles from "./AppLayout.module.css";
import AppSiderContext from "./AppSiderContext";
import AppSiderProvider from "./ASProvider";
import { logout } from "store";

const { Header, Content } = Layout;


const AppLayout:FC = () => {
    // redux constants
    const dispatch = useDispatch();
    const { data } = useAppSelector(({user}) => user);
    const { collapse } = useContext(AppSiderContext);

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: 'Cerrar Sesión',
            onClick: () => dispatch(logout())
        }
    ];

    return (
        <AppSiderProvider>
            <Layout style={{ minHeight: "100vh" }}>
                <Header className={styles["navbar-content"]}
                    style={{
                        padding: "0 10px",
                        position: "sticky",
                        left: 0,
                        top: 0,
                        bottom: 0,
                        zIndex: 5
                    }}
                >
                    <div style={{
                        width: collapse ? 80 : 200,
                        textAlign: "center"
                    }}>
                        <Navbar.Brand
                            as={Link}
                            to="/home"
                            style={{ color: "var(--white-color)" }}
                        >
                            {collapse ? <Fragment><b>G</b>L</Fragment> : <Fragment><b>Good</b>Look</Fragment>}
                        </Navbar.Brand>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "0 35px" }}>
                        <Dropdown menu={{items}}>
                            <div style={{ height: 45, display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <Avatar
                                    backgroundColor={data.photo_uri ? "transparent" : "purple"}
                                    icon={data.photo_uri ? <Image className={styles["user-avatar"]}
                                    preview={false} image={data.photo_uri} alt="user-logo" /> : <UserOutlined />}
                                />
                                <p style={{ color: "var(--white-color)"}} className="m-0">{data?.name || ""}</p>
                            </div>
                        </Dropdown>
                    </div>
                </Header>
                <Layout style={{ minHeight: "calc(100vh - 64px)" }}>
                    <AppSider />
                    <Layout>
                        <Content>
                            <Outlet />
                        </Content>
                        <Footer style={{ textAlign: "center"}}>{"Copyright © 2025 Good Look. All Rights Reserved."}</Footer>
                    </Layout>
                </Layout>
            </Layout>
        </AppSiderProvider>
    )
}
export default AppLayout;
