import { Col, Divider, Empty, notification, Row } from "antd";
import { postSale } from "api/private";
import { Button, Table } from "components";
import I18n from "i18n-js";
import { useState,useEffect } from "react";
import { cartColumns } from "utils/tableColumns";

export interface AddressI {
    latitude:                    string;
    longitude:                   string;
    street:                      string;
    number:                      string;
    zipcode:                     string;
    details:                     string;
    zone_id:                     number;
    alias:                       string;
    locality:                    string;
    sublocality:                 string;
    neighborhood:                string;
    administrative_area_level_1: string;
 }
 
export interface CardI {
    card_number: string;
    status:      string;
    token:       string;
 }
 
export interface CustomerI {
    lastNames: string;
    name:      string;
    phone:     string;
    email:     string;
 }
 

export interface Item {
    product_id: number;
    quantity:   number;
 }

 export interface SaleI {
    date?:         string;
    payment_type?: string;
    promo_code?:   string;
    items?:        Item[];
 }

 export interface AnonymousSale {
    sale?: SaleI;
    customer?: CustomerI;
    card?:     CardI;
    address?:  AddressI;
 }

 export interface CartPropsI {
    list: [];
    currency: 'MXN' | 'USD' | 'EUR';
    updateCart: any;
    requestBody: AnonymousSale;
    promoCode:any;
    goBack: any;
    clearForm: any
}

const Cart = ({list, currency, updateCart, requestBody, promoCode, goBack, clearForm}:CartPropsI)=> { 

    const [products, setProducts] = useState<any>(list);
    const [total, setTotal] = useState(0);
    const [items, setItems] = useState([]);

    const handleSendRequest = async () => {
        const body = {
            ...requestBody,
            sale: {
                ...requestBody.sale,
                promo_code: promoCode ? promoCode.code : '',
                items
            }
        }
        const clearAll = () => {
            setProducts([]);
            setTotal(0);
            setItems([]);
            updateCart([]);
            clearForm();
        }

        try{
            const {success} = await postSale(body);
            if(success){
                notification.success({
                    message: "Registro Exitoso.",
                    description: 'Puedes consultar la venta en el módulo de servicios.'
                });
                clearAll();
                goBack(1);
            }else{
                notification.error({
                    message: "Error en el registro.",
                    description: 'Ocurrio un error al registrar la venta, vuelve a intentarlo.'
                });
            }
        }catch(e:any){
            console.log('e: ', e);
        }
    }
    
    useEffect(()=>{
        const aux = products.map((p:any)=>{return {product_id: p.id, quantity: p.count}});
        setItems(aux);
    },[products]);

    useEffect(()=>{
        if(list.length > 0){
            setProducts(list);
        }
    },[list]);

    const updateCount = (id:number, operation: 'add' | 'subtract') => {
        const updatedProducts = () => {
            return products.map((p:any)=>{
                return p.order !== id ? p : {...p, count: operation === 'add' ? p.count + 1 : operation === 'subtract' && p.count - 1}
            })
        }
        setProducts(updatedProducts);   
    }

    const removeItem = (id:number) => {
        const aux = products.filter((pf:any)=> pf.order !== id );
        setProducts(aux);   
        updateCart(aux);
    }

    const getTotal = () => {
        return products.reduce((acumulator:number, obj:any) => {
            return acumulator + (currency === 'MXN' ? obj.sell_price : obj.sell_price_usd) * obj.count;
          }, 0);
    }

    useEffect(()=>{
       setTotal(getTotal());
    },[products, currency]);

    return ( 
        <div className='reg-flexcenter-content' >
            <div className='title-section'>
                <Table
                    columns={cartColumns(currency, updateCount, removeItem)}
                    dataSource={products}
                    size="small"
                    rowKey={'id'}
                    locale={{
                        triggerDesc: 'Ordenar de forma descendente',
                        triggerAsc: 'Ordenar de forma ascendente',
                        cancelSort: 'Cancelar ordenamiento',
                        emptyText: (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="Agrega Servicios a la Lista"
                        />
                        )
                    }}
                    scroll={{ x: 'auto' }}
                    pagination={false}
                />
                <Divider style={{ margin: "20px 0"}} />
                <Row style={{width: '100%', display: 'flex', alignItems: 'flex-start', flexDirection: 'column', paddingLeft: '67%'}} >
                    <h6 style={{margin: '1rem 22.5% 2rem 0'}} >{"Resumen"}</h6>
                    {promoCode && promoCode.discount !== 0 &&  <p style={{marginRight: '8%'}} >Costo del Servicio:  <span style={{fontWeight: 700}} > { I18n.l('currency', total)} {currency}</span> </p>}
                    {/* <p style={{marginRight: '8%'}} >Costo Extra: { I18n.l('currency', 0) }</p> */}
                    {promoCode && promoCode.discount !== 0 && <p style={{marginRight: '8%'}} >Promoción: <span style={{fontWeight: 700}} > { `-${promoCode.discount}%` }</span > </p>}
                    <p style={{marginRight: '8%'}} >Total: <span style={{fontWeight: 700}} >{ I18n.l('currency', total * (promoCode ? ((100-promoCode.discount)/100) : 1) ) } {currency}</span> </p>
                </Row>
                <Divider style={{ margin: "20px 0"}} />
                <Row style={{display: 'flex', justifyContent: 'center', width: '100%', marginTop: '1rem'}} >
                    <Col style={{width: '100%', display: 'flex', justifyContent: 'space-between'}} xs={12} sm={12} md={12} lg={12}>
                        <Button size="sm" className="me-3 pry-outline-btn" onClick={()=>goBack(1)} >
                            {"Regresar"}
                        </Button>
                        <Button size="sm" onClick={handleSendRequest} className="primary-btn" disabled={!(items.length > 0)}>
                            {"Continuar"}
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
        )} 

 export default Cart;