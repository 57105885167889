import { notification, Spin } from "antd";
import { Button, Divider, Dragger, Input, InputNumber, Modal, Select, TextArea } from "components";
import { useAppSelector, useS3Upload } from "hooks";
import { useEffect, useState } from "react";
import { Col, Form, Modal as M, Row } from "react-bootstrap";
import { activeEnum } from "utils/enums";
import { s3config } from "utils/s3config";
import styles from "pages/settings/GoodLooks.module.css";

interface ProductModalPropsI {
    closeModal: () => void;
    okHandler: (obj:any) => void;
    visible: boolean;
    categoryName?: string;
}

const ProductFormModal = ({ closeModal, okHandler, visible, categoryName }: ProductModalPropsI) => {
    const [name, setName] = useState("");
    const [nameEng, setNameEng] = useState("");
    const [description, setDescription] = useState("");
    const [descriptionEng, setDescriptionEng] = useState("");
    const [sellPrice, setSellPrice] = useState("0");
    const [sellPriceUSD, setSellPriceUSD] = useState("0");
    const [sellPriceEUR, setSellPriceEUR] = useState("0");
    const [duration, setDuration] = useState("");
    const [statusProduct, setStatusProduct] = useState("");
    const [photo, setPhoto] = useState<any>("");
    const [photoBlob, setPhotoBlob] = useState("");
    const [s3Photo, setS3Photo] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);

    const { isLoading, uploadingFile } = useS3Upload();
    const { product } = useAppSelector(({catalogs}) => catalogs);

    const handleHideModal = () => {
        handleClearState();
        closeModal();
    }

    const handleChange = ({target}:any) => {
        const {value, id} = target;
        switch (id) {
            case "product":
                setName(value);
                break;
            case "productEng":
                setNameEng(value);
                break;
            case "description":
                setDescription(value);
                break;
            case "descriptionEng":
                setDescriptionEng(value);
                break;
            default:
                return null;
        }
    }

    const handleClearState = () => {
        setName("");
        setNameEng("");
        setDescription("");
        setDescriptionEng("");
        setSellPrice("");
        setSellPriceUSD("");
        setSellPriceEUR("");
        setDuration("");
        setStatusProduct("");
        setPhoto("");
        setPhotoBlob("");
        setS3Photo("");
    }

    const handleNumberInput = (typed:string) => (value:any) => {
        switch (typed) {
            case "sell":
                setSellPrice(value);
                break;
            case "sellUSD":
                    setSellPriceUSD(value);
                    break;
            case "sellEUR":
                    setSellPriceEUR(value);
                    break;
            case "min":
                setDuration(value);
                break;
            default:
                return null;
        }
    }

    const handleSelect = (value:any) => {
        setStatusProduct(value);
    }

    const handleSubmit = (e:any) => {
        e.preventDefault();
        okHandler({
            name: {en: nameEng, es: name},
            description: {en: descriptionEng, es: description},
            photo: s3Photo,
            status: statusProduct === "active",
            cost_price: 0,
            sell_price: sellPrice,
            sell_price_usd: sellPriceUSD,
            sell_price_eur: sellPriceEUR,
            duration
        });
    }

    const fileValidation = (file:any) => {
        if (file.size < 500000)
            return true;
        notification.error({
            message: "Error",
            description: "La imagen excede el támaño permitido"
        });
        setPhoto("");
        setPhotoBlob("");
        return false;
    }

    const formValidation = () => {
        if (name && nameEng && description  && descriptionEng && sellPrice && sellPriceUSD && duration && statusProduct) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }

    const uploadAwsS3 = async () => {
        try {
            const { success, location, error } = await uploadingFile(photo, 
                {
                    ...s3config,
                    dirName: categoryName ? `products/${categoryName}` : "products"
                });
            if (success) {
                setS3Photo(location);
            } else {
                setS3Photo("");
                notification.error({
                    message: "Error",
                    description: error || "Error en S3"
                });
            }
        } catch (error:any) {
            setS3Photo("");
            notification.error({
                message: "Error",
                description: error.message
            });
        }
    }

    useEffect(() => {
        formValidation();
    }, [name, nameEng, description, descriptionEng, sellPrice, sellPriceUSD, duration, statusProduct])

    useEffect(() => {
        if (photo)
            uploadAwsS3();
    }, [photo])

    useEffect(() => {
        if (Object.keys(product).length > 0) {
            setName(product.name.es);
            setNameEng(product.name.en);
            setDescription(product.description.es);
            setDescriptionEng(product.description.en);
            setStatusProduct(product.status ? "active" : "inactive");
            setPhotoBlob(product.photo);
            setS3Photo(product.photo);
            setSellPrice(product.sell_price);
            setSellPriceUSD(product.sell_price_usd);
            setSellPriceEUR(product.sell_price_eur);
            setDuration(product.duration ? product.duration.toString() : "");
        }
    }, [])

    return (
        <Modal show={visible} centered size="lg"
            onHide={handleHideModal} backdrop="static" keyboard={false}>
            <M.Header>
                <h6 className="m-0">{`${Object.keys(product).length > 0 ? "Editar" : "Agregar"} Producto`}</h6>
            </M.Header>
            <M.Body>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <h6>{"Nombre de Producto"}</h6>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Input
                                id="product"
                                name="product"
                                label="Español"
                                classNameContainer="mb-2"
                                value={name}
                                onChange={handleChange}
                                maxLength={50}
                                showCount
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Input
                                id="productEng"
                                name="productEng"
                                label="Inglés"
                                classNameContainer="mb-2"
                                value={nameEng}
                                onChange={handleChange}
                                maxLength={50}
                                showCount
                            />
                        </Col>
                        <h6>{"Descripción de Producto"}</h6>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextArea
                                id="description"
                                name="description"
                                label="Español"
                                classNameContainer="mb-2"
                                value={description}
                                onChange={handleChange}
                                maxLength={400}
                                showCount
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <TextArea
                                id="descriptionEng"
                                name="descriptionEng"
                                label="Inglés"
                                classNameContainer="mb-2"
                                value={descriptionEng}
                                onChange={handleChange}
                                maxLength={400}
                                showCount
                            />
                        </Col>
                        <Divider style={{ margin: "8px 0"}}/>
                        <Col xs={12} sm={12} md={4} lg={4}>
                            <InputNumber
                                id="sellPrice"
                                name="sellPrice"
                                label="Precio de Venta (MXN)"
                                formatter={(value:any) => `$${value}`}
                                parser={(value:any) => value.replace('$', '')}
                                classNameLabel="w-100"
                                classNameContainer="mb-2"
                                style={{ width: "100%" }}
                                min={0}
                                defaultValue={sellPrice}
                                value={sellPrice}
                                onChange={handleNumberInput("sell")}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4}>
                            <InputNumber
                                id="sellPriceUSD"
                                name="sellPriceUSD"
                                label="Precio de Venta (USD)"
                                formatter={(value:any) => `$${value}`}
                                parser={(value:any) => value.replace('$', '')}
                                classNameLabel="w-100"
                                classNameContainer="mb-2"
                                style={{ width: "100%" }}
                                min={0}
                                defaultValue={sellPriceUSD}
                                value={sellPriceUSD}
                                onChange={handleNumberInput("sellUSD")}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4}>
                            <InputNumber
                                id="sellPriceUSD"
                                name="sellPriceUSD"
                                label="Precio de Venta (EUR)"
                                formatter={(value:any) => `€${value}`}
                                parser={(value:any) => value.replace('€', '')}
                                classNameLabel="w-100"
                                classNameContainer="mb-2"
                                style={{ width: "100%" }}
                                min={0}
                                defaultValue={sellPriceEUR}
                                value={sellPriceEUR}
                                onChange={handleNumberInput("sellEUR")}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <InputNumber
                                id="duration"
                                name="duration"
                                label="Duración"
                                classNameLabel="w-100"
                                classNameContainer="mb-2"
                                style={{ width: "100%" }}
                                min={0}
                                text={"Cifra en minutos"}
                                defaultValue={duration}
                                value={duration}
                                onChange={handleNumberInput("min")}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Select
                                id="statusEvent"
                                classNameContainer="mb-2"
                                label="Estatus"
                                classNameLabel="w-100"
                                options={activeEnum}
                                value={statusProduct}
                                onChange={handleSelect}
                                style={{ width: "100%" }}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Spin spinning={isLoading}>
                                <Dragger
                                    label={"Foto"}
                                    classNameContainer="mb-2"
                                    accept="image/*"
                                    beforeUpload={(file) => {
                                        if (fileValidation(file)) {
                                            setPhoto(file);
                                            setPhotoBlob(URL.createObjectURL(file));
                                        }
                                        return false;
                                    }}
                                    showUploadList={false}
                                    image={photoBlob}
                                    classNameImage={styles["product-image"]}
                                />
                            </Spin>
                        </Col>
                    </Row>
                    <Divider style={{ margin: "16px 0"}} />
                    <Row>
                        <Col className={styles["col-form"]} xs={12} sm={12} md={{ offset: 4, span: 8}} lg={{ offset: 6, span: 6}}>
                            <Button size="sm" className="me-3 pry-outline-btn" onClick={handleHideModal}>
                                {"Cancelar"}
                            </Button>
                            <Button size="sm" className="primary-btn" type="submit" disabled={isDisabled}>
                                {"Enviar"}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </M.Body>
        </Modal>
    )
}
export default ProductFormModal;